<template>
	<div id="app">
		<HomeIndex v-if="!isMobile" />
		<HomeIndexMobile v-else />
	</div>
</template>

<script>
	import HomeIndex from './components/HomeIndex.vue'
	import HomeIndexMobile from './components/HomeIndexMobile.vue'

	export default {
		name: 'App',
		components: {
			HomeIndex,
			HomeIndexMobile
		},
		data() {
			return {
				isMobile: false
			}
		},
		created() {
			this.checkMobile();
		},
		methods: {
			checkMobile() {				
				const userAgent = navigator.userAgent || navigator.vendor || window.opera;
				this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
			}
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		padding: 0px;
		margin: 0px;
		/* margin-top: 60px; */
	}
</style>