<template>
	<div class="mobeil_page flex-col">
		<div class="mobeil_group_1 flex-col align-center">
			<div class="mobeil_box_1 flex-col">
				<div class="mobeil_group_2 flex-row align-center">
					<span class="mobeil_text_1">トレジャーハブ株式会社</span>
				</div>
			</div>
			<img id="scroll0" class="mobeil_image_7" referrerpolicy="no-referrer"
				src="./assets/img/28112e18791ceb12ae595bb3d18d9a77.png" />
			<div id="scroll1" class="flex-col align-center" style="width: 100%;">
				<span class="mobeil_text_7">Corporate&nbsp;Vision</span>
				<div class="mobeil_text-group_6 flex-col">
					<span class="mobeil_text_8">“エンターテインメントを楽しもう！新たな生活をスタートしよう！”</span>
					<span class="mobeil_paragraph_2">
						私たちが目指すのは、リアルでしか体験できない豊富な娯楽のオンライン化です。
						<br />
						ここでしか感じられない楽しさと面白さを大切にし、プレイヤーがもっとフラットに交流やプレイできる環境を整えたいと思っています。
						<br />
						新たな娯楽の概念を再定義し、リアルな体験をオンラインでも実現できるよう努めます。
						<br />
					</span>
				</div>
				<!-- <div class="mobeil_text-wrapper_3 flex-col">
					<span class="mobeil_text_9">張浩&nbsp;&nbsp;&nbsp;代表取締役</span>
					<span class="mobeil_paragraph_3">
						トレジャーハブを立ち上げる前には、
						<br />
						別のインターネット企業で取締役
						<br />
						総裁およびパートナーを兼任していました。
					</span>
				</div> -->
			</div>
			<div class="mobeil_box_8 flex-col align-center">
				<span class="mobeil_text_14">くじ鶏</span>
				<span class="mobeil_text_15">Let's&nbsp;Gacha&nbsp;Now!</span>
				<div class="mobeil_image_div_1">
					<img class="mobeil_image_1" referrerpolicy="no-referrer"
						src="./assets/img/29fd7728ecedaf8aeed8ac9989021fba.png" />
					<img class="mobeil_image-wrapper_1" referrerpolicy="no-referrer"
						src="./assets/img/9a248c012585c5aa776434947ead30ab.png" />
					<img class="mobeil_image_2" referrerpolicy="no-referrer"
						src="./assets/img/92de833ca5d028bb89d1910640ac8e14.png" />
					<img class="mobeil_image_3" referrerpolicy="no-referrer"
						src="./assets/img/ae095718d3cbad095599baac1c2430b9.png" />
				</div>
			</div>
			<div class="mobeil_box_17 flex-col justify-start">
				<span class="mobeil_text_10">くじ鶏</span>
				<span class="mobeil_paragraph_5">
					いつでもどこでも遊べるオンラインガチャです。
					<br />
					色々な人気アニメフィギュアがあります。
					<br />
					多様な遊び方、多様な賞品で楽しんでください
				</span>
				<span class="mobeil_text_11">24時間オンラインガチャゲームです</span>
				<div class="mobeil_box_18 flex-row">
					<div class="mobeil_text-wrapper_6 display-center"><span class="mobeil_text_12">ダウンロード方法</span></div>
					<div class="mobeil_text-wrapper_7 display-center"><span class="mobeil_text_13">ソーシャルアカウント</span></div>
				</div>
			</div>
			<!-- <div class="mobeil_text-group_9 flex-col">
				<span class="mobeil_paragraph_8 display-center">
					ハートビート&nbsp;
					<br />
					の瞬間
				</span>
				<span class="mobeil_text_16">香港で&nbsp;発売中</span>
			</div>
			<div class="mobeil_block_2 flex-col">
				<img class="mobeil_image_4_1" referrerpolicy="no-referrer"
					src="./assets/img/4bbea803b9858ce3d3a9fa60fbb05256.png" />
				<img class="mobeil_image_5_1" referrerpolicy="no-referrer"
					src="./assets/img/2a4ddf114599bebef12f74fad6b79584.png" />
				<img class="mobeil_image_4" referrerpolicy="no-referrer"
					src="./assets/img/7e58231dd2f47d723340eb92979608f0.png" />
				<img class="mobeil_image_5" referrerpolicy="no-referrer"
					src="./assets/img/7a4cc2edb8578d2300ffe24926b4aa29.png" />
			</div>
			<span class="mobeil_paragraph_10">
				Heartbeat
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moments
			</span>
			<span class="mobeil_paragraph_11">
				心臓の鼓動が速まるゲームの瞬間を感じ、
				<br />
				大奨を獲得しましょう！さあ、挑戦しましょう！
			</span>
			<span class="mobeil_text_17">オンラインソーシャルスロットゲームサービス</span>
			<div class="mobeil_block_9 flex-row justify-between">
				<div class="mobeil_text-wrapper_10 display-center"><span class="mobeil_text_18">ダウンロード方法</span></div>
				<div class="mobeil_text-wrapper_11 display-center"><span class="mobeil_text_19">ソーシャルアカウント</span></div>
			</div> -->
			<span class="mobeil_text_20">Company</span>
			<div class="mobeil_text-wrapper_13 flex-row">
				<span class="mobeil_text_22">会社名:</span>
				<span class="mobeil_text_24">トレジャーハブ株式会社</span>
			</div>
			<div class="mobeil_text-wrapper_14 flex-row">
				<span class="mobeil_text_25">所在地:</span>
				<span class="mobeil_paragraph_14">東京都文京区本郷1-35-26
					<br />
					SKYTECビル4F
				</span>
			</div>
			<span class="mobeil_text_27">Contact</span>
			<span class="mobeil_paragraph_16">
				トレジャーハブへご興味をお持ちいただき、ありがとうございます。業務連絡やインタビュー  
				<br />
				に関しては、お気軽にご連絡ください。
				<br />
			</span>
			<div class="mobeil_text-wrapper_17">
				<span class="mobeil_text_29">ゲームに関するご質問があれば、</span>
				<span class="mobeil_text_30">contact&#64;kujitori.jp</span>
				<span class="mobeil_text_31">までお気軽にお問い合わせください。</span>
			</div>
			<div id="scroll3" class="mobeil_view_101 flex-col">
				<span class="mobeil_text_32">お名前</span>
				<div class="mobeil_text-wrapper_18 flex-col">
					<input v-model="userName" placeholder="お名前"
						style="width: calc(100% - 20px);height: calc(100% - 20px);margin: 10px;border: none;font-size: 15px;outline: none" />
				</div>
				<div class="mobeil_text-wrapper_19 flex-row">
					<span class="mobeil_text_34">お問い合わせ種別</span>
					<span class="mobeil_paragraph_17">
						*
					</span>
				</div>
				<div class="mobeil_section_2 flex-col">
					<div class="mobeil_flex-row align-center" @click="clickQu(0)">
						<div class="mobeil_group_5 display-center">
							<div v-if="selectQu==0" class="mobeil_group_6"></div>
						</div>
						採用に関するお問い合わせ
					</div>
					<br/>
					<div class="flex-row align-center" @click="clickQu(1)">
						<div class="mobeil_group_5 display-center">
							<div v-if="selectQu==1" class="mobeil_group_6"></div>
						</div>
						取材のお申し込み
					</div>
					<br/>
					<div class="flex-row align-center" @click="clickQu(2)">
						<div class="mobeil_group_5 display-center">
							<div v-if="selectQu==2" class="mobeil_group_6"></div>
						</div>
						サービス導入や提案などのご案内
					</div>
					<br/>
					<div class="flex-row align-center" @click="clickQu(3)">
						<div class="mobeil_group_5 display-center">
							<div v-if="selectQu==3" class="mobeil_group_6"></div>
						</div>
						その他
					</div>
				</div>
				<span class="mobeil_text_37">お問い合わせ内容</span>
				<div class="mobeil_block_6">
					<textarea v-model="userContent" class="mobeil_text_38" placeholder="お問い合わせ内容"></textarea>
				</div>
				<span class="mobeil_text_39">メールアドレス</span>
				<div class="mobeil_text-wrapper_21 flex-col">
					<input v-model="userEmail" placeholder="メールアドレス"
						style="width: calc(100% - 20px);height: calc(100% - 20px);margin: 10px;border: none;font-size: 15px;outline: none" />
					<span v-if="emailError" style="color: red;margin-top: 12px;">正しいメールアドレスを入力してください</span>
				</div>
				<!-- <span class="text_41">認証コード</span>
				<div class="text-wrapper_22 flex-col">
					<input v-model="userEmail" placeholder="認証コード"
						style="width: calc(100% - 20px);height: calc(100% - 20px);margin: 10px;border: none;font-size: 30px;outline: none" />
				</div> -->
				<div class="mobeil_view_111 flex-row justify-center">
					<button class="mobeil_text-wrapper_23 display-center" @click="clickSend()"><span
							class="mobeil_text_43">送信する</span></button>
				</div>
				<span class="mobeil_text_49 flex-row justify-center">著作権:&nbsp;トレジャーハブ&nbsp;株式会社</span>
				<!-- <div class="block_5 flex-col">
					<div class="box_21 flex-row justify-between">
						<div class="text-wrapper_26 flex-col justify-between">
							<span class="text_44">トレジャーハブ株式会社</span>
							<span class="paragraph_21">
								私たちはオンラインゲームを通じて、プレイヤーに革新的で娯楽的な交流のプラットフォーム
								<br />
								を提供し、無限の楽しみと刺激的な体験を与えます。私たちは伝統的なゲームの境界を打ち破
								<br />
								り、日本や世界中のプレイヤーに向けてエキサイティングなデジタルエンターテインメント空
								<br />
								間を創造し、ゲームに参加するすべてのユーザーに私たちの情熱と創造性を感じてもらいた
								<br />
								い。
							</span>
						</div>
						<div class="group_10 flex-col">
							<span class="text_45">トップページ</span>
							<div class="box_15 flex-col"></div>
							<span class="text_46">企業情報</span>
							<span class="text_47">サービス</span>
							<span class="text_48">お問い合わせ</span>
						</div>
					</div>
					<div class="text-wrapper_27 flex-row">
						<span class="text_49">著作権:&nbsp;トレジャーハブ&nbsp;株式会社</span>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		name: 'HomeIndexMobile',
		data() {
			return {
				constants: {},
				tabSelectIndex: 0,
				selectQu: 0,
				userName: '',
				userContent: '',
				userEmail: '',
				contents: ['採用に関するお問い合わせ', '取材のお申し込み', 'サービス導入や提案などのご案内', 'その他'],
				isSend: false
			};
		},
		computed: {
			emailError() {
				// 使用正则表达式验证邮箱格式
				const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
				return this.userEmail.length > 0 && !emailPattern.test(this.userEmail);
			}
		},
		methods: {
			clickTabIndex(index, selector) {
				if (this.tabSelectIndex == index) {
					return
				}
				this.tabSelectIndex = index;
				const element = document.getElementById(selector);
				if (element) {
					element.scrollIntoView({
						behavior: 'smooth'
					});
				}
			},
			clickQu(index) {
				this.selectQu = index;
			},
			clickSend() {
				if (this.removeSpaces(this.userName).length == 0) {
					alert('お名前を入力してください。');
					return;
				}

				if (this.removeSpaces(this.userContent).length == 0) {
					alert('お問い合わせ内容を入力してください。');
					return;
				}

				if (this.removeSpaces(this.userEmail).length == 0) {
					alert('メールをお願いします。');
					return;
				}

				// 使用正则表达式验证邮箱格式
				const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
				if (!emailPattern.test(this.userEmail)) {
					alert('正しいメールアドレスを入力してください');
					return;
				}

				if (this.isSend) {
					return
				}
				this.isSend = true;

				axios.post('https://api-dev.treasureshub.jp/app/suggestion/send', {
					"name": this.userName, // 姓名
					"type": this.contents[this.selectQu], // 类型
					"context": this.userContent, // 内容
					"email": this.userEmail // 邮箱
				}).then(res => {
					alert('送信完了');
					console.log(res);
				}).catch(error => {
					console.error('请求用户数据出错:', error);
				});
			},
			removeSpaces(str) {
				return str.replace(/\s+/g, '');
			}
		}
	};
</script>
<style lang='postcss'>
	@import './assets/indexMobile.css';
	@import '../assets/common.css';
</style>
<!-- <style scoped lang="css" src="./assets/indexMobile.css" />
<style scoped lang="css" src="../assets/common.css" /> -->